@use "../config" as *;
// common style/card style
.waf-overlay-list {
    .article {
        &-list {
            overflow-x: auto;
            @extend %flex;
            @extend %flex-nowrap;
            @extend %gap-4;
        }
        &-title {
            @extend %title-50;
        }
    }
}
.waf-horizontal-list {
    &.waf-listing {
        .article-title {
            @extend %mb-0;
        }
    }
}
.waf-listing.waf-multimedia-listing {
    margin-top: -16.2rem;
    @extend %relative;
    @extend %zindex-content;
    .title,
    .head-tab li a {
        color: var(--neutral-5);
    }
    .head-tab li a {
        &:hover {
            color: var(--neutral-20);
        }
        &:active {
            color: var(--neutral-0);
        }
        &:disabled {
            color: var(--neutral-40);
        }
    }
    .article-list {
        display: flex;
    }
}
.waf-row-listing {
    .waf-horizontal-list {
        @extend %mt-16;
        @extend %mb-24;
    }
}
@include mq(col-tablet) {
    .waf-overlay-list.waf-listing {
        .article {
            &-list {
                @include grid(2, var(--space-6));
            }
            &-title {
                height: 10.1rem;
            }
        }
    }
    .waf-multimedia-listing.waf-listing {
        .article {
            &-list {
                @include grid(3, var(--space-6));
            }
        }
    }
}
@media (min-width:$lg-min-width) {
    .waf-listing {
        .article {
            &-list {
                grid-template-columns: repeat(2, 1fr);
            }
            &-title {
                -webkit-line-clamp: 4;
            }
            &-item {
                &:hover {
                    .article-thumbnail img {
                        transform: scale(1.05);
                    }
                }
            }
        }
    }
    .waf-listing.waf-horizontal-list {
        .article {
            &-list {
                grid-template-columns: repeat(2, 1fr);
                gap: var(--space-6);
            }
        }
    }
    .waf-overlay-list.waf-listing {
        .article {
            &-list {
                @include grid(3, var(--space-6));
            }
        }
    }
    .waf-listing.waf-multimedia-listing {
        margin-top: -21rem;
    }
    .waf-row-listing {
        .waf-horizontal-list {
            margin-top: 8.8rem;
            margin-bottom: 18.6rem;
        }
    }
}