@use "../config" as *;
// Education Hub styles
.waf-development-education {
    --card-link-size: 2.2rem;
    padding-block: var(--space-4) var(--space-20);
    margin-inline: var(--space-2-neg);
    margin-bottom: 0;
    .waf-head {
      padding: var(--space-6) var(--space-6) 8.6rem;
      flex-direction: column-reverse;
      @include background(var(--primary-50), "cssimages/pattern/education-bg-m.png", top center / cover no-repeat);
      @extend %border-radius-m;
      @extend %flex;
      @extend %gap-14;
    }
    .left {
      @extend %neutral-5;
    }
    .sponsor-logo {
      width: 15.3rem;
    }
    .title {
      @extend %title-40;
    }
    .text {
      @extend %mb-8;
      @extend %body-mb;
    }
    .btn-site {
      @include flex-config(inline-flex, null, null, center);
      @extend %p-3-5;
      @extend %rounded-radius;
      @extend %bg-neutral-0;
      @extend %gap-2;
      &::after {
        transform: rotate(45deg);
        @include icon(arrow-up, 24, 24);
        @extend %primary-50;
      }
      &:hover {
        @extend %bg-neutral-5;
        &::after, .btn-text{
          @extend %primary-55;
        }
      }
      &:focus {
          &::after, .btn-text{
            @extend %primary-85;
          }
      }
    }
    .btn-text {
      font-weight: 700;
      @extend %primary-50;
    }
    .waf-body {
      margin-top: -3.6rem;
      @extend %px-6;
    }
    .card {
      &-list {
        @include card-count(1.1, var(--space-4));
        @extend %pr-10;
        @extend %mr-10-neg;
      }
      &-wrap {
        height: 16rem;
        border: .1rem solid var(--neutral-20);
        background: var(--neutral-5) linear-gradient(223deg, var(--informative-10) 0%, hsl(var(--hsl-informative-10) / 0) 75.04%);
        @extend %p-4;
        @extend %border-radius-s;
        @extend %flex-column;
        @extend %relative;
      }
      &-tag {
        width: fit-content;
        border: .1rem solid var(--neutral-20);
        @extend %p-2-3;
        @extend %rounded-radius;
        @extend %bg-neutral-0;
        @extend %text-mb;
      }
      &-title {
        margin-block: auto 0;
      }
      &-link {
        width: calc(100% - var(--card-link-size) - var(--space-4));
        @include truncate-text(3);
        @extend %d-inline-block;
        @extend %primary-50;
        @extend %title-50;
        &::after {
          @include position(null, var(--space-4), var(--space-4));
          @include icon(arrow-right, 22, 22);
        }
      }
      &-content {
        @extend %d-none;
      }
    }
}
@include mq(col-tablet) {
    .waf-development-education {
        padding-top: var(--space-20);
        .waf-head {
          padding: var(--space-8) var(--space-8) 16rem;
          position: relative;
          contain: paint;
          flex-direction: row;
          gap: var(--space-4);
          isolation: isolate;
          @include bgImg("cssimages/pattern/education-bg-d.png");
          &::before {
            content: "";
            width: 37.4rem;
            aspect-ratio: 1;
            z-index: map-get($map: $zindex, $key: pattern);
            pointer-events: none;
            @include position(-2.8%, -2.65%);
            @include background(null, "svg/vector-light-blue.svg", center / contain no-repeat);
          }
        }
        .title {
          font-size: 4rem;
          line-height: 1.2;
        }
        .text {
          font-size: 2.4rem;
          line-height: 1.4;
        }
        .waf-body {
          padding-inline: var(--space-8);
          margin-top: -8.8rem;
        }
        .card {
          &-list {
            padding-right: 0;
            margin-right: 0;
            @include card-count(2, var(--space-4));
          }
          &-wrap {
            height: 18.2rem;
            border-radius: var(--border-radius-m);
          }
          &-content {
            margin-top: var(--space-6);
            display: block;
            font-size: 1.6rem;
            line-height: 1.5;
          }
          &-enrol {
            display: block;
            width: fit-content;
            text-decoration: underline;
          }
        }
      }
}

@include mq(col-lg) {
    .waf-development-education .card-list {
        @include card-count(3, var(--space-4));
    }
}

@include mq(col-desktop) {
    .waf-development-education {
        .waf-head {
          padding: var(--space-14) var(--space-14) 16rem;
          justify-content: space-between;
        }
        .left {
          width: 72.6%;
        }
        .waf-body {
          padding-inline: var(--space-14);
        }
        .card-list {
          @include card-count(4, var(--space-4));
        }
        .sponsor-logo {
          width: 17.5rem;
        }
    }
}