@use "../config/" as *;
.waf-component.waf-event-strip {
    @extend %p-0;
}
.load-bg-pattern {
    .waf-row-event.waf-row-upcoming-events {
        background-position: center -50rem;
    }
}
.waf-row-event {
    &:has(.card-item) {
        + .waf-row {
            .waf-featured-event {
                margin-top: -16rem;
                .title {
                    @extend %neutral-0;
                }
            }
        }
    }
    .waf-event-strip {
        .head-tab a {
            &:hover {
                @extend %primary-55;
            }
            &:focus {
                @extend %primary-85;
            }
        }
    }
}
.waf-row-multisport-event {
    &:has(.card-item) {
        + .waf-row {
            .waf-overlay-list {
                margin-top: -26.4rem;
                .title {
                    @extend %neutral-0;
                    @include title-with-icon(shape, photo, 24, neutral-0);
                    &::before {
                        @extend %neutral-0;
                    }
                }
                .head-tab a {
                    @extend %neutral-5;
                    &:hover {
                        @extend %neutral-20;
                    }
                    &:active {
                        @extend %neutral-0;
                    }
                    &:disabled {
                        @extend %neutral-40;
                    }
                }
            }
        }
    }
    + .waf-row {
        :has(:not(.waf-overlay-list)) {
            .waf-component.waf-event-strip {
                margin-bottom: 0;
            }
        }
    }
}
.waf-event-strip {
    @extend %relative;
    @extend %common-eventstrip;
    .layout-wrapper {
        max-width: calc(var(--window-inner-width) - var(--container-white-space));
        margin-inline: auto 0;
    }
    .title {
        @extend %title-40;
        @extend %neutral-100;
        @extend %mb-8;
        @include title-with-icon(null, megaphone-fill, 18, null);
        &:before {
            justify-content: flex-start;
        }
    }
    .head {
        &-wrap {
            @extend %gap-2;
            @extend %flex-column;
        }
        &-tab {
            @include position-combo(x-center, null, -1rem);
            @extend %zindex-overlay-icon;
            a {
                width: max-content;
                @extend %m-auto;
                @extend %btn-fill;
            }
        }
    }
    .card {
        &-item {
            width: 30.2rem;
            height: 40.2rem;
            overflow: hidden;
            @extend %relative;
            &:last-child {
                @extend %mr-4;
            }
        }
        &-list {
            @include card-count(1.2, var(--space-4), wrap);
            @extend %mr-4;
        }
        &-thumbnail {
            @extend %mt-9-neg;
            .img-box {
                border-radius: unset;
            }
        }
        &-head {
            height: 13.5rem;
        }
        &-body {
            border-radius: var(--border-radius-m) var(--border-radius-m) 0 0;
            height: calc(100% - 15.8rem);
            padding-bottom: 0;
        }
        &-source {
            height: auto;
            max-width: 15.4rem;
            z-index: 3;
            @include position(1rem, 1.3rem);
        }
        &-title {
            @include truncate-text(3);
            @extend %title-50;
        }
        &-status {
            .label {
                @extend %capitalize;
            }
        }
        &-image {
            object-fit: cover;
        }
        &-action {
            border-radius: 0 0 var(--border-radius-m) var(--border-radius-m);
            @extend %px-6;
            @extend %pb-6;
        }
        &-btn {
            .text {
                @extend %bg-neutral-0;
                &:hover {
                    border-color: var(--primary-50);
                    @extend %bg-primary-50;
                    @extend %neutral-0;
                }
            }
        }
    }
    .category {
        @include truncate-text(1);
        @extend %body-l;
        @extend %neutral-70;
    }
    .cancelled,
    .postponed,
    .live,
    .new-date {
        .counter {
            @extend %d-none;
        }
    }
    .cancelled {
        .card-status {
            @extend %bg-negative-0;
            .label {
                @extend %negative-90;
            }
        }
    }
    .postponed {
        .card-status {
            @extend %bg-notice-0;
            .text {
                @extend %notice-90;
            }
        }
    }
    .new-date {
        .card-status {
            @extend %bg-positive-0;
            .text {
                @extend %positive-90;
            }
        }
    }
    .counter {
        @extend %gap-2;
        @extend %flex;
        &-item {
            line-height: 1;
        }
    }
    .counter,
    .status {
        @extend %pl-1;
        @extend %ml-1;
        @include separator(70%, y, 0.1rem, var(--neutral-20));
        .text {
            @extend %text-s;
            @extend %neutral-70;
        }
        &::after {
            left: -0.4rem;
            right: unset;
        }
    }
    .status {
        @extend %flex-n-c;
        .text {
            display: contents;
            &::after {
                @include icon(live, 20);
            }
        }
    }
    .swiper {
        &-button-prev,
        &-button-next,
        &-pagination {
            @extend %d-none;
        }
    }
}
.waf-row-event {
    isolation: isolate;
    @extend %relative;
    @extend %common-gradient;
    .waf-event-strip {
        &.widget-layout-04 {
            padding-bottom: 8rem;
            .head-tab {
                bottom: 0rem;
            }
        }
        .title {
            @include title-with-icon(shape, megaphone-fill, 18, neutral-0);
            @extend %neutral-0;
            @extend %zindex-overlay-icon;
        }
    }
    .layout-wrapper {
        padding-top: 12rem;
    }
}
.waf-row-multisport-event {
    .waf-component.waf-event-strip {
        margin-bottom: 37rem;
    }
    @extend %border-radius-xl;
    @extend %hidden;
    .waf-event-strip {
        .head-tab a {
            &:hover {
                @extend %primary-55;
            }
            &:active {
                @extend %primary-85;
                @extend %bg-neutral-0;
            }
        }
    }
}
.sticky-container-eventstrip {
    & > div {
        @extend %px-0;
    }
}
//eventstrip page
.waf-row-eventstrip {
    padding-block: 11rem 23rem;
    @include bgImg('cssimages/eventlisting-bg.png', top, cover);
    @extend %w-100;
    @extend %h-100;
    .waf-event-strip {
        .head-tab {
            @extend %d-none;
        }
        .title {
            @include title-with-icon(shape, calendar, 18, neutral-0);
            @extend %neutral-0;
        }
    }
}
.waf-more-events {
    margin-bottom: 0;
    .layout-wrapper {
        @extend %relative;
    }
    .head-tab {
        bottom: 0;
        a {
            border-color: var(--neutral-5);
            background-color: transparent;
            color: var(--neutral-5);
            @extend %btn-outline;
        }
    }
}
.wtc-event-page {
    .waf-more-events {
        padding-block: var(--space-14) 9.6rem;
    }
}
.waf-row-showcase-strip {
    .waf-component.waf-event-strip {
        margin-top: calc((var(--window-inner-height) / 2) * -1);
        @extend %pt-0;
        @extend %mb-0;
        .title {
            @extend %neutral-0;
            @extend %title-40;
            &::before {
                content: unset;
            }
        }
    }
}
.waf-row-arena {
    margin-top: -4rem;
    border-radius: var(--border-radius-m) var(--border-radius-m) 0 0;
    &::before {
        border-radius: var(--border-radius-m) var(--border-radius-m) 0 0;
    }
    .waf-event-strip {
        padding-bottom: 0;
        margin-bottom: 0;
    }
    .layout-wrapper {
        padding-top: var(--space-10);
    }
}
.waf-row-development {
    border-radius: 0 0 var(--border-radius-m) var(--border-radius-m);
    &::before,
    &::after {
        border-radius: 0 0 var(--border-radius-m) var(--border-radius-m);
        z-index: map-get($map: $zindex, $key: pattern);
    }
    .waf-event-strip.widget-layout-04 {
        padding-bottom: 17.6rem;
        margin-bottom: 0;
        .head-tab {
            bottom: 9.6rem;
            a {
                background-color: var(--neutral-0);
                color: var(--primary-50);
            }
        }
    }
    .card-list {
        margin-right: 0;
    }
}
@include mq(col-tablet) {
    .waf-component.waf-event-strip {
        padding-block: var(--space-20);
    }
    .waf-event-strip {
        .layout-wrapper {
            max-width: var(--container-max-width);
            margin-inline: auto;
        }
        .card {
            &-list {
                padding-inline: 0;
                @include card-count(2.3, 2rem, wrap);
            }
            &-item {
                &:last-child {
                    margin-right: auto;
                }
            }
        }
    }
    .load-bg-pattern {
        .waf-row-event.waf-row-upcoming-events {
            background-position: center -40rem;
        }
    }
    .waf-row-event {
        .preview-swiper {
            position: static;
        }
        .layout-wrapper {
            padding-top: 24rem;
        }
        .card-list {
            overflow: initial; //overriding mixin value
        }
        .swiper {
            &-button-prev,
            &-button-next {
                display: flex;
                left: auto;
                @include position(36rem, calc(var(--container-white-space) + 6rem));
                &::after {
                    font-size: 2.4rem;
                }
            }
            &-button-next {
                right: var(--container-white-space);
            }
            &-pagination-fraction {
                display: flex;
                left: auto;
                width: max-content;
                color: var(--neutral-0);
                height: max-content;
                @include position(38rem, 21rem);
            }
            &-pagination-current,
            &-pagination-total {
                font-size: 1.6rem;
                color: var(--neutral-0);
            }
        }
    }
    .waf-row-eventstrip {
        padding-bottom: 0;
    }
    .waf-more-events {
        &.waf-event-strip {
            .title {
                font-size: 3.2rem;
                margin-top: var(--space-6);
                &:before {
                    content: unset;
                }
            }
        }
        .card-item {
            width: calc(83.33% - 2.4rem);
        }
        .layout-wrapper {
            display: flex;
        }
        .waf {
            &-head {
                width: 30.2rem;
            }
            &-body {
                width: calc(100% - 30.2rem);
            }
        }
    }
    .waf-row-multisport-event {
        .swiper-pagination,
        .swiper-button-prev,
        .swiper-button-next {
            display: none;
        }
    }
    .waf-row-arena {
        margin-top: -16rem;
        border-radius: var(--border-radius-xl) var(--border-radius-xl) 0 0;
        &::before {
            border-radius: var(--border-radius-xl) var(--border-radius-xl) 0 0;
        }
        .layout-wrapper {
            padding-top: 32rem;
        }
    }
    .waf-row-development {
        border-radius: 0 0 var(--border-radius-xl) var(--border-radius-xl);
        &::before,
        &::after {
            border-radius: 0 0 var(--border-radius-xl) var(--border-radius-xl);
        }
        .waf-event-strip.widget-layout-04 {
            padding-bottom: 15.2rem;
            .head-tab {
                bottom: var(--space-16);
            }
        }
    }
    .waf-row-showcase-strip {
        .waf-component.waf-event-strip {
            margin-top: -15rem;
        }
    }
}
@include mq(col-lg) {
    .waf-event-strip {
        .waf-head {
            padding-inline: 0;
        }
        .head-wrap {
            gap: var(--space-2);
        }
        .head-tab {
            a {
                @include btn-config-1(fill-dark, icon, primary, neutral);
            }
        }
        .title {
            font-size: 4rem;
            margin-bottom: var(--space-10);
        }
        .card {
            &-list {
                @include card-count(3, var(--space-6));
                overflow: hidden; //overriding mixin value
            }
        }
    }
    .waf-row-event {
        .card-list {
            overflow: initial;
            flex-wrap: nowrap;
            gap: 0;
        }
    }
    .waf-row-eventstrip {
        border-radius: 4rem 4rem 0 0;
        @include bgImg('svg/noise-gradient.svg');
    }
    .waf-more-events {
        .card {
            &-list {
                justify-content: space-between;
                overflow-x: auto;
            }
            &-item {
                width: calc(50% - 1.6rem);
            }
        }
    }
}
@include mq(col-desktop) {
    .waf-event-strip .card-list {
        @include card-count(4, var(--space-6));
    }
    .waf-row-event {
        .card-list {
            overflow: initial;
            flex-wrap: nowrap;
            gap: 0;
        }
    }
    .waf-more-events {
        .card {
            &-list {
                overflow-x: hidden;
            }
            &-item {
                width: calc(33.33% - 1.6rem);
            }
        }
    }
}
@include mq(col-xl) {
    .waf-row-event {
        .swiper-button-next {
            right: calc((var(--window-inner-width) - var(--container-max-width)) / 2);
        }
        .swiper-button-prev {
            right: calc(((var(--window-inner-width) - var(--container-max-width)) / 2) + 6rem);
        }
        .swiper-pagination-fraction {
            right: calc(((var(--window-inner-width) - var(--container-max-width)) / 2) + 13.2rem);
        }
    }
}