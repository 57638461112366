@use "../config" as *;
// Hero Banner styles
.waf-development-hero {
  padding-block: 0;
  margin-bottom: 0;
  @extend %development-page-head-bg;
  @extend %accordion-common;
  .waf-body {
    @include default-wrapper();
    @extend %p-6-4;
    @extend %border-radius-m;
    @extend %bg-neutral-0;
  }
  .card {
    &-label {
      @extend %m-y-0-8;
      @extend %title-40;
    }
  }
  .text {
    @extend %neutral-70;
    @extend %body-l;
    &:not(:last-child) {
      @extend %mb-6;
    }
  }
  .label-content-wrap {
    @extend %mb-8;
  }
}
// Programme styles
.waf-development-programme {
  padding-block: var(--space-20);
  margin-bottom: 0;
  .programme-title {
    @extend %mb-10;
    @extend %title-40;
  }
  .card {
    &-list {
      @extend %flex-column;
      @extend %gap-6;
    }
    &-item {
      box-shadow: 0 .2rem 1.5rem 0 hsl(var(--hsl-pure-black-0) / .15);
      @extend %p-6-4;
      @extend %border-radius-m;
    }
    &-title {
      @extend %title-40;
    }
    &-text {
      @extend %mb-8;
      @extend %body-l;
    }
    &-action {
      gap: var(--space-4) var(--space-2);
      @extend %flex;
      @extend %flex-wrap;
    }
  }
  .btn-site {
    width: max-content;
  }
  .wt-programme-list {
    @extend %mb-16;
    .card {
      &-item {
        background: linear-gradient(180deg, var(--primary-50) 23.67%, var(--dark-focus-0) 100%);
        @extend %neutral-5;
      }
      &-title {
        @extend %neutral-0;
      }
    }
    .btn-site {
      @extend %btn-outline-dark;
    }
  }
  .other-programme-list {
    .card-text {
      @extend %neutral-70;
    }
    .btn-site {
      @extend %btn-outline;
    }
  }
}
// Quote section styles
.waf-development-quote {
  padding-block: var(--space-10) var(--space-20);
  margin-inline: var(--space-2-neg);
  margin-bottom: 0;
  @extend %relative;
  &::before {
    content: "";
    aspect-ratio: .385;
    pointer-events: none;
    @include background(null, "cssimages/pattern/development-gradient-m.png", center / cover no-repeat);
    @extend %w-100;
    @extend %position-t-l;
    @extend %zindex-pattern;
  }
  .layout-wrapper {
    @extend %text-center;
  }
  .quote {
    @extend %mb-6;
    @extend %title-50;
  }
  .quote-src {
    @extend %neutral-70;
    @extend %body-mb;
  }
}
// Meet Team World Triathlon styles
.waf-development-meet {
  padding-block: 0 var(--space-20);
  border-radius: var(--border-radius-xl) var(--border-radius-xl) 0 0;
  margin: 0 var(--space-2-neg) 0;
  @extend %bg-neutral-100;
  .layout-wrapper {
    max-width: unset;
  }
  .thumbnail {
    isolation: isolate;
    @extend %relative;
    &::before,
    &::after {
      content: "";
      border-radius: var(--border-radius-xl) var(--border-radius-xl) 0 0;
      pointer-events: none;
      @extend %w-100;
      @extend %position-t-l;
      @extend %zindex-default;
    }
    &::before {
      aspect-ratio: .8;
      background: linear-gradient(180deg, hsl(var(--hsl-neutral-100) / 0) 0%, hsl(var(--hsl-neutral-100) / .99) 34.1%, var(--neutral-100) 100%);
    }
    &::after {
      height: 23%;
      background: linear-gradient(0deg, hsl(var(--hsl-neutral-100) / 0) 0%, var(--neutral-100) 87.5%);
    }
    &-img {
      border-radius: var(--border-radius-xl) var(--border-radius-xl) 0 0;
    }
  }
  .emblem {
    width: 6.3rem;
    aspect-ratio: .61;
    @include position(0, var(--space-10));
  }
  .content {
    padding-inline: var(--container-white-space);
    @extend %flex-column;
    @extend %relative;
    @extend %zindex-default;
    @extend %gap-6;
    @extend %text-center;
  }
  .title {
    @extend %mb-0;
    @extend %neutral-0;
    @extend %title-40;
  }
  .text {
    @extend %body-l;
    @extend %neutral-5;
  }
  .btn-site {
    min-height: 4.8rem;
    @include flex-config(inline-flex, null, null, center);
    @extend %px-5;
    @extend %rounded-radius;
    @extend %bg-neutral-0;
    &:hover {
      @extend %bg-neutral-5;
      @extend %primary-55;
    }
    &:focus {
        @extend %primary-85;
    }
  }
  .btn-text {
    @extend %primary-50;
    @extend %text-mb;
  }
}
// News listing styles
.waf-horizontal-list {
  padding-block: var(--space-10);
  margin-bottom: 0;
  .head-wrap {
    align-items: flex-end;
  }
  .title {
    margin-bottom: var(--space-3);
    @include title-with-icon(null, megaphone-fill, 20);
    &::before {
      color: var(--neutral-40);
    }
  }
}
// Photo, Video Listing styling
.waf-overlay-list {
  padding-block: var(--space-10);
  margin-bottom: 0;
  &.waf-listing .article-list {
    @include card-count(1.1, var(--space-4));
  }
  .title {
    @extend %flex-column;
    &::before {
      color: var(--neutral-40);
      font-weight: 400;
      @include icon(photo, 24);
    }
  }
  .head-wrap {
    align-items: flex-end;
  }
  .waf-body {
    margin-right: var(--space-4-neg);
  }
  .article-list {
    padding-right: var(--space-4);
  }
  .pagination-wrap {
    @extend %d-none;
  }
}
// Video Listing styling
.waf-video-list {
  padding-bottom: var(--space-20);
  .title::before {
    @include icon(play, 24);
  }
}
.waf-row-development-events {
  @include bgImg('cssimages/development-event-listing-bg.jpg', null, cover);
}
@include mq(col-tablet) {
  .waf-development-hero {
    .waf-body {
      padding: var(--space-14) var(--space-16);
      border-radius: var(--border-radius-l);
    }
    .card-label {
      font-size: 3.2rem;
      line-height: 1.3;
    }
  }
  .waf-development-programme {
    padding-block: var(--space-16) 10.4rem;
    .programme-title,
    .card-title {
      font-size: 3.2rem;
      line-height: 1.3;
    }
    .card-item {
      padding: var(--space-8);
    }
  }
  .waf-development-quote {
    padding-block: 15.8rem;
    &::before {
      aspect-ratio: 2.88;
      top: 10%;
      @include background(null, "cssimages/pattern/development-gradient-d.png", center / cover no-repeat);
    }
    .quote {
      font-size: 3.2rem;
      line-height: 1.3;
    }
    .quote-src {
      font-size: 2.4rem;
      line-height: 1.4;
    }
  }
  .waf-development-meet {
    border-radius: 4rem 4rem 0 0;
    .thumbnail {
      &::before,
      &::after {
        border-radius: 4rem 4rem 0 0;
      }
      &::before {
        height: 100%;
        aspect-ratio: unset;
        background: linear-gradient(180deg, hsl(var(--hsl-neutral-100) / 0) 19.33%, hsl(var(--hsl-neutral-100) / .99) 75.19%, var(--neutral-100) 100%);
      }
      &::after {
        height: 50%;
        background: linear-gradient(0deg, hsl(var(--hsl-neutral-100) / 0) 30.98%, var(--neutral-100) 91.37%);
      }
      &-img {
        border-radius: 4rem 4rem 0 0;
      }
    }
    .emblem {
      width: 10.42%;
      right: var(--container-white-space);
    }
    .title {
      font-size: 4rem;
      line-height: 1.2;
    }
    .content {
      margin-top: -15%;
    }
  }
  .waf-horizontal-list {
    padding-top: var(--space-20);
    .title {
      margin-bottom: var(--space-2);
    }
  }
  .waf-overlay-list {
    &.waf-listing {
      .article {
        &-list {
          @include card-count(2, var(--space-6));
        }
        &-title {
          height: 8.4rem;
        }
      }
    }
    .waf-body {
      margin-right: 0;
    }
    .article-list {
      padding-right: 0;
    }
  }
}
@include mq(col-lg) {
  .waf-development-hero {
    .label-content-wrap {
      margin-bottom: var(--space-22);
      display: grid;
      gap: var(--space-14);
      grid-template-columns: 35% auto;
    }
    .card-label {
      margin-bottom: 0;
    }
    .accordion-section {
      @include card-count(2, var(--space-16), wrap);
      row-gap: 0;
    }
  }
  .waf-development-programme {
    .card {
      &-list {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
      &-item {
        @include flex-config(flex, column);
      }
      &-action {
        margin-top: auto;
      }
    }
  }
  .waf-development-quote::before {
    top: 0;
  }
  .waf-overlay-list {
    &.waf-listing {
      .article-list {
        @include card-count(3, var(--space-6));
      }
      .article-title {
        height: 9.9rem;
      }
    }
  }
}
@include mq(col-desktop) {
  .waf-development-programme .card-item {
    padding: var(--space-14);
  }
  .waf-development-meet .content {
    width: 63.3%;
    padding-inline: 0;
    margin-inline: auto;
  }
  .waf-overlay-list {
    &.waf-listing .article-list {
      @include card-count(4, var(--space-6));
    }
  }
  .waf-video-list {
    padding-bottom: var(--space-10);
  }
}